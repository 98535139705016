@keyframes rotateLoadIcon {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotateLoadIcon {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

$voffs: 2; // vertical correction offset (lineheight)

a[role=button] {
    cursor: pointer;
}

.btn_line {

    height: 18px;
    margin: 0 15px 18px;
    @include line-height(18);
    text-align: center;
    border-bottom: 4px solid $grey_04;

    &--big {
        @include min-screen($xlg) {
            height: 23px;
        }
    }

    &.has_btn {
        margin: 9px 15px 36px;
    }
}

.btn {
    // defaults
    position: relative;
    display: inline-block;
    @include border-box();
    font: $font_prim--bold;
    @include font-size(14);
    @include line-height(40 + $voffs);
    color: $grey_01;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s;
    background: $yellow_prim;
    // mob speciffic
    height: 40px;
    padding: 0 20px;
    border: none;

    cursor: pointer;

    &:hover {
        background: $yellow_sec;
    }

    &--small {

        height: 30px;
        padding: 0 15px;
        @include line-height(30 + $voffs);
    }

    &--big {

        @include min-screen($xlg) {

            font: $font_prim--bold;
            @include font-size(14);
            @include letter-spacing(0);
            @include line-height(40 + $voffs);

            @include min-screen($lg) {
                height: 50px;
                padding: 0 30px;
                @include line-height(50 + $voffs);
            }
        }
    }

    &--sec {
        padding: 0 12px;
        font: $font_prim--med;
        @include font-size(14);
        @include line-height(40 + $voffs);
    }

    &--small {

        height: 30px;
        padding: 0 12px;
        font: $font_prim--med;
        @include font-size(13);
        @include line-height(30 + $voffs);
    }

    &--dark {

        color: $white;
        background: $grey_01;

        &:hover {
            background: $grey_00;
        }
    }

    &--grey {

        background: $grey_05;
    }

    &--ghost {

        background: none;
        font: $font_prim--med;
        @include font-size(14);
        @include line-height(42);

        &:hover {
            background: $grey_05;
        }
    }

    &--fb,
    &--fb_blue {

        color: $white;
        background: $color_fb;

        &:hover {
            background: $color_fb_01;
        }
    }

    &--gg,
    &--gg_white {

        color: $color_gpl;
        background: $white;
        border: 1px solid $grey_03;

        &:hover {
            background: $grey_05;
        }
    }

    &--tw,
    &--tw_blue {

        color: $white;
        background: $color_tw;

        &:hover {
            background: $color_tw_01;
        }
    }

    &--icon_left {

        $iconWsm: 40;
        $iconHsm: 30;
        $iconW: 40;
        $iconH: 40;
        position: relative;
        height: 40px;
        padding-left: 0px + $iconW;
        padding-right: 12px;

        &:after {
            content: " ";
            position: absolute;
            top: 0;
            bottom: 0;
            left: $iconW + 0px;
            width: 0;
            height: 20px;
            margin: auto;
            border-left: 1px solid transparent;
            opacity: .2;
            transition: background-color 0.15s;
        }

        .icon {
            position: absolute;
            top: -1px;
            left: -1px;
            width: $iconW + 0px;
            height: $iconH + 0px;
            @include font-size(20);
            @include line-height($iconH + $voffs);
            color: $grey_01;
            text-align: center;
            transition: color 0.15s;
        }

        &.btn--small {

            height: 30px;

            .icon {
                height: $iconHsm + 0px;
                @include line-height($iconHsm + $voffs);
            }
        }
    }

    &--icon_right {

        $iconWsm: 40;
        $iconHsm: 30;
        $iconW: 40;
        $iconH: 40;
        position: relative;
        height: 40px;
        padding-left: 12px;
        padding-right: 0px + $iconW;

        &:after {
            content: " ";
            position: absolute;
            top: 0;
            bottom: 0;
            right: $iconW + 0px;
            width: 0;
            height: 20px;
            margin: auto;
            border-right: 1px solid transparent;
            opacity: .2;
            transition: background-color 0.15s;
        }

        .icon {
            position: absolute;
            top: -1px;
            right: -1px;
            width: $iconW + 0px;
            height: $iconH + 0px;
            @include font-size(20);
            @include line-height($iconH + $voffs);
            color: $grey_01;
            text-align: center;
            transition: color 0.15s;
        }
    }

    &--wide {

        display: block;
        margin: 0 12px;
        padding: 0 20px 0;

        @include min-screen($sm) {
            display: inline-block;
            width: 300px;
            margin: 0;
        }

        .icon {
            color: $white;
        }
    }

    &--full {
        display: block;
        padding: 0 20px 0;

        .icon {
            color: $white;
        }

        &.is_loading {
            background-color: #fff;
            color: $yellow_prim;
        }
    }

    &--send {

        .status_loading {
            display: none;
        }

        .icon {
            position: absolute;
            left: 10px;
            width: 35px;
            height: 35px;
            @include font-size(20);
            @include line-height(35 + $voffs);
            text-align: center;

            &:before {
                display: block;
                width: 20px;
                height: 20px;
                margin: auto;
            }
        }

        &.is_loading {

            .status_default {
                display: none;
            }

            .status_loading {
                display: block;
                padding-left: 20px;
            }

            .icon {
                animation: rotateLoadIcon 2s linear infinite;
                -webkit-animation: rotateLoadIcon 2s linear infinite;
            }
        }
    }

    &--view_more {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        padding: 0 9px;
        background-color: $yellow_prim;
        font: $font_prim--bold;
        @include font-size(14);
        color: $grey_01;
        text-transform: uppercase;
    }
}

.btn-disabled {
    background: $grey_02;
}

// social icons
.social {

    &__wrap {
        position: relative;
        text-align: center;
        height: 42px;

        @include max-screen($lg - 1) {
            white-space: nowrap;
            text-align: center;
        }
    }

    &__checkbox:checked ~ .social__btn--tw,
    &__checkbox:checked ~ .social__btn--fb {

        @include max-screen($lg - 1) {
            display: none;
        }
    }

    &__btn {
        position: relative;
        box-sizing: border-box;
        @include transition (0.2s);

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: #fff;
        }
    }

    &__btn--fb,
    &__btn--tw {
        display: inline-block;
        margin-right: 17px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $yellow_prim;
        position: relative;
        transition: all 0.15s;

        &:hover {
            background-color: yellow_prim02;
        }

        i {
            color: #fff;
        }
    }

    &__group {
        position: relative;
        display: inline-block;
        height: 40px;
        margin-right: 0px;

        @include transition (0.2s);

        @include min-screen($lg) {
            width: 40px;
        }

        .social__btn--viber,
        .social__btn--whatsapp {
            @include min-screen($lg) {
                display: none;
            }
        }

        label {
            width: 40px;
            height: 40px;
            position: absolute;
            left: 0;

            &:before {
                display: block;
                content: '';
                box-sizing: border-box;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                background-color: $blue_prim;
                @include transition (0.2s);

                .social__checkbox:checked ~ & {
                    width: 178px;

                    @include min-screen($lg) {
                        width: 100px;
                    }
                }
            }

            &:hover:before {
                background-color: #35acd7;
            }

            i {
                position: absolute;
                top: 8px;
                left: 8px;
                color: #fff;
                @include transition (0.2s);

                .social__checkbox:checked ~ & {
                    @include rotate(45deg);
                    color: #007fab;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        .social__btn {
            width: 40px;
            height: 40px;
            display: inline-block;
            float: left;
        }

        &:hover {
            cursor: pointer;
        }

        .btn__group {
            position: relative;
            z-index: 10;
            display: block;
            width: 0;
            height: 40px;
            margin-left: 40px;
            font-size: 0;
            line-height: 0px;
            line-height: 0rem;
            overflow: hidden;
            @include transition (0.2s);

            .social__checkbox:checked ~ & {
                width: auto;

                @include min-screen($lg) {
                    width: 60px;
                }
            }

            .social__btn--viber,
            .social__btn--whatsapp {
                @include min-screen($lg) {
                    display: none;
                }
            }
        }

        .social__checkbox:checked ~ & {
            overflow: visible;
        }
    }

    &__checkbox {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        visibility: hidden;
    }
}
